<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body printableArea"><h3>
        <b>Laporan Surat Keluar</b>
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <form method="post" @submit.prevent="filterData">
              <div class="form-body">
                <div class="row">
                  <div class="pull-left">
                    <div class="form-group row">
                      <label class="control-label text-right mt-2 col-md-5">Tanggal Kirim</label>
                      <div class="col-md-7">
                        <input
                        type="date"
                        class="form-control"
                        data-date=""
                        data-date-format="YYYYY-MM-DD"
                        v-model="start_date"
                        />
                      </div>
                    </div>
                  </div>
                  <!--/span-->
                  <div class="pull-right">
                    <div class="form-group row">
                      <label class="control-label text-right mt-2 col-md-3">s/d</label>
                      <div class="col-md-9">
                        <input
                          type="date"
                          class="form-control"
                          data-date=""
                          data-date-format="YYYYY-MM-DD"
                          v-model="end_date"
                        >
                      </div>
                    </div>
                  </div>
                  <!--/span-->
                </div>
                <div class="row">
                  <div class="pull-left">
                    <div class="form-group row">
                      <label class="control-label text-right mt-2 col-md-5">Nama Pembuat Surat</label>
                      <div class="col-md-7">
                        <input
                        type="text"
                        placeholder="NIP/NIK"
                        class="form-control"
                        v-model="pembuat"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="pull-left">
                    <div class="form-group row">
                      <label class="control-label text-right mt-2 col-md-5">Unit / OPD</label>
                      <div class="col-md-7">
                        <input type="text" placeholder="Unit / OPD" class="form-control" v-model="unit">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="form-actions">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-offset-3 col-md-9">
                        <button type="submit" class="btn btn-success" title="Simpan">Submit</button>&nbsp;
                        <button type="button" @click="resetInput()" class="btn btn-inverse" title="Reset">Reset</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row justify-content-md-center" v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
          <h3>
          <b>&nbsp;</b>
          <div class="pull-right">
            <button @click="onDownload" class="btn btn-sm btn-success">
              <i class="mdi mdi-printer"></i> Print
            </button>&nbsp;
            <!-- <downloadexcel
              class="btn btn-sm btn-success"
              :fetch="exportExcel"
              :fields="excel_fields"
              worksheet="Laporan Surat Keluar"
              name="laporan-surat-keluar.xls">
                Export Excel
            </downloadexcel>&nbsp; -->
            <button
              @click="exportExcelData('daftarlaporanout')"
              class="btn btn-sm btn-success"
              style="margin-right: 10px"
            >
              Export Excel
            </button>
          </div>
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :onAction="pagination"
              :filter="filters"
              :header="headers"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- {{ this.$store.state.laporanDokumenOut }} -->

  </div>
</template>
<script>
import Table from "@/components/table/table";
import moment from 'moment';
import { RotateSquare5 } from "vue-loading-spinner";
import downloadexcel from "vue-json-excel";
import axios from 'axios';

var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var htmlToPdfmake = require("html-to-pdfmake");

export default {
  components: {
    RotateSquare5,
    Table,
    downloadexcel
  },
  data() {
    return {
      start_date : moment().startOf('year').format('YYYY-MM-DD'),
      end_date : moment().endOf('year').format('YYYY-MM-DD'),
      pembuat : "",
      unit : "",
      tanggal : new Date().getDate()+'-'+(new Date().getMonth()+1)+'-'+new Date().getFullYear(),
      headers: {
        date_sent_text: {
          label: "Waktu Pengiriman",
          sortable: true,
        },
        document_number: {
          label: "Nomor Dokumen",
          sortable: false,
        },
        document_date: {
          label: "Tanggal Dokumen",
          sortable: false,
          type: "date"
        },
        sifat_surat: {
          label: "Sifat Surat",
          sortable: false,
        },
        subject: {
          label: "Perihal",
          sortable: false,
        },
        sender: {
          label: "Dari",
          sortable: false,
        },
        receiver: {
          label: "Kepada",
          sortable: false,
          tooltip: "receiverTooltip",
        },
        jenis_dokumen: {
          label: "Jenis Dokumen",
          sortable: false,
        },
        keterangan_surat: {
          label: "Keterangan Surat",
          sortable: false,
        },
        halaman: {
          label: "Jumlah Halaman",
          sortable: false,
        },
      },
      excel_fields: {
        'Nomor Urut':'urutan',
        'Waktu Pengiriman':'date_sent_text',
        'Nomor Dokumen':'document_number',
        'Tanggal Dokumen':'document_date',
        'Sifat Surat':'sifat_surat',
        'Perihal':'subject',
        'Dari':'sender',
        'Kepada':'kepada',
        'Jenis Dokumen':'jenis_dokumen',
        'Keterangan Surat':'keterangan_surat',
        'Jumlah Halaman':'halaman'
      },
    };
  },
  computed: {
    items() {
      return this.$store.state.laporanDokumenOut.items ? this.$store.state.laporanDokumenOut.items.items : [];
    },
    prints() {
      return this.$store.state.laporanDokumenOut.items ? this.$store.state.laporanDokumenOut.items.prints : [];
    },
    filters() {
      return this.$store.state.laporanDokumenOut.items ? this.$store.state.laporanDokumenOut.items.filters: {};
    },
    state() {
      return this.$store.state.laporanDokumenOut;
    },
    forbidUpdate(){
      if(this.$store.state.profile.permissions.laporan_docout){
        return !this.$store.state.profile.permissions.laporan_docout.update;
      }
      return false;
    },
    forbidRead(){
      if(this.$store.state.profile.permissions.laporan_docout){
        return !this.$store.state.profile.permissions.laporan_docout.read;
      }
      return false;
    },
    forbidDel(){
      if(this.$store.state.profile.permissions.laporan_docout){
        return !this.$store.state.profile.permissions.laporan_docout.delete;
      }
      return false;
    }
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
      prints: [],
    };
    this.$store.commit("laporanDokumenOut/STATE", state);
    this.get();
  },
  methods: {
    exportExcelData(val) {
      const link = document.createElement("a");
      let unit_id = JSON.parse(localStorage.getItem("user")).unit_id;
      link.href = `${this.baseUrl}/${val}/export-excel?start_date=${this.start_date}&end_date=${this.end_date}&unit_id=${unit_id}`;
      link.target = "_blank";
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    get(val) {
      this.$store.dispatch("laporanDokumenOut/getLaporanDokumenOut", val);
    },
    resetInput() {
      this.start_date = "";
      this.end_date = "";
      this.pembuat = "";
      this.unit = "";
      this.get(val);
    },
    pagination(params) {
      this.filterData(null, params);
    },
    filterData: function (event, params) {
      var val = {
        start_date: this.start_date,
        end_date: this.end_date,
        pembuat: this.pembuat,
        unit: this.unit,
      }
      if(typeof(params) == "object" && params.constructor.name === "Object") {
        val = Object.assign(params, val);
      }
      this.get(val);
    },
    doDetail(val) {
      this.$store.dispatch("laporanDokumenOut/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("laporanDokumenOut/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("laporanDokumenOut/submitDelete", val);
    },
    onDownload() {
      var data = Array();
      var header = [
        { text: "Nomor Urut", style: "tableHeader" },
        { text: "Tanggal Pengiriman", style: "tableHeader" },
        { text: "Nomor Dokumen", style: "tableHeader" },
        { text: "Tanggal Dokumen", style: "tableHeader" },
        { text: "Sifat Surat", style: "tabelHeader" },
        { text: "Perihal", style: "tableHeader" },
        { text: "Dari", style: "tableHeader" },
        // { text: "Kepada", style: "tableHeader" },
        { text: "Jenis Dokumen", style: "tableHeader" },
        { text: "Keterangan Surat", style: "tableHeader" },
        { text: "Hal", style: "tableHeader" },
      ];

      data.push(header);
      var prints = this.prints;
      var result = Object.keys(prints).map(function(key) {
        return [Number(key), prints[key]];
      });
      var i = 1;
      result.forEach(element => {
        data.push([
          i,
          element[1].document_date,
          element[1].document_number,
          element[1].document_date,
          element[1].sifat_surat,
          // element[1].perihal,
          htmlToPdfmake(element[1].subject || "-"),
          element[1].sender,
          element[1].jenis_dokumen,
          // element[1].keterangan_surat,
          htmlToPdfmake(element[1].keterangan_surat || "-"),
          element[1].halaman
        ]);
        i++;
      });
      var documentDefinition = {
        pageOrientation: 'landscape',
        content: [
          {
            marginLeft: 50,
            marginRight: 50,
            // width: [1000],
            table: {
              body: [
                [
                  {
                    text: "Pemerintah Daerah Daerah Istimewa Yogyakarta",
                    style: "header",
                    margin: [200, 10, 0, 0]
                  },
                ],
                [
                  {
                    text: "Laporan Surat Keluar",
                    style: "header",
                    margin: [200, 10, 0, 0]
                  }
                ],
              ]
            },
            layout: "headerLineOnly"
          },
          // {
          //   marginTop: 10,
          //   table: {
          //     body: [
          //       [
          //         {
          //           text: "Unit Kerja",
          //           width: 50,
          //           marginRight: 50,
          //           style: "tableHeader"
          //         },
          //         ":",
          //         this.$store.state.laporanDokumenOut.items.nama_satker
          //       ]
          //     ]
          //   },
          //   layout: "noBorders",
          //   style: "tableHeader"
          // },
          {
            marginTop: 10,
            table: {
              body: [
                [
                  {
                    text: "Unit Kerja",
                    width: 50,
                    marginRight: 50,
                    style: "tableHeader"
                  },
                  ":",
                  this.$store.state.laporanDokumenOut.items.nama_satker
                ]
              ]
            },
            layout: "noBorders",
            style: "tableHeader"
          },
          {
            marginTop: 10,
            table: {
              body: [
                [
                  {
                    text: "Tanggal",
                    width: 50,
                    marginRight: 50,
                    style: "tableHeader"
                  },
                  ":",
                  this.start_date + " s.d " + this.end_date
                ]
              ]
            },
            layout: "noBorders",
            style: "tableHeader"
          },
          {
            marginTop: 15,
            table: {
              body: data
            },
            style: "tableHeader"
          },
          {
            marginTop: 10,
            text: "",
            style: "tableHeader"
          },
          {
            text: "Yogyakarta, "+ this.tanggal,
            style: "header2",
            marginLeft: 600
          },
          {
            text: this.$store.state.laporanDokumenOut.items.nama_satker,
            style: "header2",
            marginLeft: 600,
          }
        ],
        styles: {
          tableHeader: {
            bold: true,
            fontSize: 6,
            color: "black",
            alignment: "left"
          },
          tableHeader2: {
            bold: true,
            fontSize: 9,
            color: "black",
            alignment: "center"
          },
          header: {
            bold: true,
            fontSize: 12,
            color: "black",
            alignment: "center"
          }
        },
        pageMargins: [40, 60, 40, 60],
        pageSize: "A4"
      };
      pdfMake.createPdf(documentDefinition).print();
    },
    async exportExcel() {

      if (this.$store.state.laporanDokumenOut.items.filters.start_date == null && this.$store.state.laporanDokumenOut.items.filters.end_date == null) {
          const response = await axios.get('laporan_docout?search=&sortable=asc');
          return response.data.prints;
      } else {
          const response = await axios.get('laporan_docout?start_date='+this.start_date+'&end_date='+this.end_date);
          return response.data.prints;
      }

    }
  }
};
</script>
